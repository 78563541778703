const Footer = () => {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright">
            © {new Date(Date.now()).getFullYear()} Cathay National.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
